import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { Link } from "gatsby"

export const ReadMoreContaner = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 2rem;
    width: 100%;
`

const ReadMoreItem = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 4rem;
    margin-right: 4rem;
    max-width: 400px;
    @media (max-width: 940px) {
        max-width: initial;
    }
`

const Image = styled.div`
    height: 128px;
    width: 128px;
    background-size: cover;
    background-position: center;
    margin-right: 2rem;
    transition: 0.3s ease-in-out;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
    cursor: pointer;
    border-radius: 5px;
    &:hover {
        transform: scale(1.02);
    }
`

export default ({ slug = "", title = "", imageUrl = "" }) => {
    return (
        <ReadMoreItem>
            <Link to={slug}>
                <Image
                    css={css`
                        background-image: url(${imageUrl});
                    `}
                />
            </Link>

            <Link
                to={slug}
                css={css`
                    display: inline-box;
                    color: #377c9e;
                    font-size: 1rem;
                    margin-bottom: 1rem;
                    &:hover {
                        color: black;
                    }
                `}
            >
                {title}
            </Link>
        </ReadMoreItem>
    )
}
