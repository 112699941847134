import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

const Modal = styled.div`
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 15px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.9); /* Black w/ opacity */
`
const ModalContent = styled.img`
    margin: auto;
    display: block;
    max-height: 900px;
    width: 80%;
    max-width: 1024px;
`

export default function LightBox({ imageUrl, onClose }) {
    return (
        <div>
            <Modal
                css={css`
                    display: ${imageUrl ? "flex" : "none"};
                `}
                onClick={onClose}
            >
                <ModalContent src={imageUrl} />
            </Modal>
        </div>
    )
}
