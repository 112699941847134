import React from "react"
import { graphql } from "gatsby"
import Container from "../components/layout/Container"
import Header from "../components/Header"
import Space from "../components/Space"
import Content from "../components/layout/Content"
import styled from "@emotion/styled"
import LightBox from "../components/LightBox"
import ButtonBack from "../components/ButtonBack"
import { Helmet } from "react-helmet"
import TrainingBanner from "../components/TrainingBanner"
import { SEO_KEYWORDS, SEO_DESCRIPTION } from "../utils"
import ReadMoreItem, { ReadMoreContaner } from "../components/ReadMoreItem"

const Title = styled.h1`
    text-align: center;
`
const TitleWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 4rem;
    div:first-of-type {
        margin-right: 1rem;
    }
`

const Step = styled.div`
    margin-bottom: 3rem;
`
const StepText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
`

const StepImages = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const StepImage = styled.img`
    margin: 1rem;
    border-radius: 5px;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
    cursor: pointer;
    transition: 0.3s ease-in-out;
    &:hover {
        transform: scale(1.05);
    }
    @media (max-width: 500px) {
        width: 100%;
    }
`

const Counter = styled.span`
    background-color: #377c9e;
    color: white;
    padding: 1rem;
    margin-right: 1rem;
`

export default props => {
    const [imageUrl, setimageUrl] = React.useState("")
    const {
        old_id,
        title,
        steps,
        keywords,
        description,
    } = props.data.mongodbVpletaysyaLessons

    const handleImageClick = React.useCallback(imgUrl => {
        setimageUrl(imgUrl)
    }, [])

    return (
        <Container>
            <Helmet>
                <title>{title}</title>
                <meta name="keywords" content={keywords || SEO_KEYWORDS} />
                <meta
                    name="description"
                    content={description || SEO_DESCRIPTION}
                />
            </Helmet>
            <Header />
            <TrainingBanner />
            <Space />
            <Content>
                <TitleWrapper>
                    <ButtonBack backUrl="/lesson">{"<"}</ButtonBack>
                    <Title>{title}</Title>
                </TitleWrapper>

                {steps.map((step, i) => (
                    <Step key={`les_step_${i}`}>
                        <StepText>
                            <Counter>{i + 1}</Counter>
                            <span>{step.text}</span>
                        </StepText>
                        <StepImages>
                            {step.images.map((img, j) => (
                                <StepImage
                                    onClick={() =>
                                        handleImageClick(
                                            `/upload-files/lesson/${old_id}/${img.fileName}`
                                        )
                                    }
                                    key={`les_img_${i}_${j}`}
                                    src={`/upload-files/lesson/${old_id}/cover/cover_${img.fileName}`}
                                />
                            ))}
                        </StepImages>
                    </Step>
                ))}
                <Space />
                <ReadMoreContaner>
                    {props.pageContext.readMore &&
                        props.pageContext.readMore.length > 0 &&
                        props.pageContext.readMore.map((data, i) => (
                            <ReadMoreItem
                                key={`read_more_${i}`}
                                title={data.title}
                                slug={data.url}
                                imageUrl={`/upload-files/lesson/${data.old_id}/cover/cover_${data.old_id}_lesson.jpg`}
                            />
                        ))}
                </ReadMoreContaner>
            </Content>
            <Space />
            <LightBox
                imageUrl={imageUrl}
                onClose={() => {
                    setimageUrl("")
                }}
            />
        </Container>
    )
}

export const lessonQuery = graphql`
    query singleLessonQuery($id: String!) {
        mongodbVpletaysyaLessons(id: { eq: $id }) {
            old_id
            old_url
            steps {
                order
                text
                images {
                    fileName
                    order
                }
            }
            text
            title
            url
            keywords
            date
            date1
            description
            published
            id
        }
    }
`
